<template>
	<div class="products">
		<div v-if="loadingResults" class="loading-icon">
			<span>&nbsp;</span>
		</div>
		<v-progress-circular
			v-if="initialLoad"
			:size="70"
			:width="7"
			color="#003056"
			indeterminate
			class="init-loader"
		></v-progress-circular>

		<template v-if="isValidVendorPartner">
			<form v-if="isMobile" class="mb-14">
				<v-container fluid>
					<v-row>
						<v-col cols="12" sm="12">
							<v-combobox
								v-model="searchForm.product"
								:items="productResults"
								label="Search"
								hint="Search Chemical or Trade Name, INCI Name, CAS #, Synonym, Former Trade Name"
								persistent-hint
								outlined
								dense
								clearable
								@change="
									($event) => {
										if ($event) {
											this.searchForm.isWildcardSearch = false;
										}
									}
								"
								@input.native="productSearchInput($event, 'product')"
								@click:clear="
									searchForm.product = '';
									productResults = [];
								"
								@keyup.enter="submit()"
								:no-data-text="noDataText"
								append-icon=""
								class="px-3"
							></v-combobox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" sm="12">
							<v-combobox
								v-model="searchForm.productClass"
								:items="productClassResults"
								label="Product Class"
								outlined
								dense
								clearable
								@change="
									($event) => {
										if ($event) {
											this.searchForm.isWildcardSearch = false;
										}
									}
								"
								@input.native="($event) => productSearchInput($event, 'productClass')"
								@click:clear="
									searchForm.productClass = '';
									productClassResults = [];
								"
								@keyup.enter="submit()"
								:no-data-text="noDataText"
								append-icon=""
								class="px-3"
								hide-details
							></v-combobox>
						</v-col>
					</v-row>

					<!-- <v-row>
					<v-col cols="12" sm="12">
						<v-combobox
							v-model="searchForm.vendor"
							:items="vendorResults"
							label="Vendor"
							outlined
							dense
							clearable
							@change="
								($event) => {
									if ($event) {
										this.searchForm.isWildcardSearch = false;
									}
								}
							"
							@input.native="($event) => productSearchInput($event, 'vendor')"
							@click:clear="
								searchForm.vendor = '';
								vendorResults = [];
							"
							@keyup.enter="submit()"
							:no-data-text="noDataText"
							append-icon=""
							class="px-3"
							hide-details
						></v-combobox>
					</v-col>
				</v-row> -->

					<v-row>
						<v-col cols="12" sm="12">
							<v-combobox
								v-model="searchForm.productUse"
								:items="productUseResults"
								label="Product Use"
								outlined
								dense
								clearable
								@change="
									($event) => {
										if ($event) {
											this.searchForm.isWildcardSearch = false;
										}
									}
								"
								@input.native="($event) => productSearchInput($event, 'productUse')"
								@click:clear="
									searchForm.productUse = '';
									productUseResults = [];
								"
								@keyup.enter="submit()"
								:no-data-text="noDataText"
								append-icon=""
								class="px-3"
								hide-details
							></v-combobox>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-list>
								<v-list-group
									v-for="item in advancedFilters"
									:key="item.title"
									v-model="item.active"
									prepend-icon="mdi-filter"
									no-action
									class="advanced-filters"
								>
									<template v-slot:activator>
										<v-list-item-content>
											<v-list-item-title v-text="item.title"></v-list-item-title>
										</v-list-item-content>
									</template>

									<v-list-item key="Advanced Filters" class="px-0 mx-0">
										<v-list-item-content>
											<v-row>
												<v-col cols="12" sm="12">
													<v-combobox
														v-model="searchForm.casNumber"
														:items="casNumberResults"
														label="CAS Number"
														outlined
														dense
														clearable
														@change="
															($event) => {
																if ($event) {
																	this.searchForm.isWildcardSearch = false;
																}
															}
														"
														@input.native="
															($event) => productSearchInput($event, 'casNumber')
														"
														@click:clear="
															searchForm.casNumber = '';
															casNumberResults = [];
														"
														@keyup.enter="submit()"
														:no-data-text="noDataText"
														append-icon=""
														class="px-3"
														hide-details
													></v-combobox>
												</v-col>
											</v-row>

											<!-- <v-row>
											<v-col cols="12" sm="12">
												<v-combobox
													v-model="searchForm.unNumber"
													:items="unNumberResults"
													label="UN Number"
													outlined
													dense
													clearable
													@change="
														($event) => {
															if ($event) {
																this.searchForm.isWildcardSearch = false;
															}
														}
													"
													@input.native="
														($event) => productSearchInput($event, 'unNumber')
													"
													@click:clear="
														searchForm.unNumber = '';
														unNumberResults = [];
													"
													@keyup.enter="submit()"
													:no-data-text="noDataText"
													append-icon=""
													class="px-3"
													hide-details
												></v-combobox>
											</v-col>
										</v-row> -->

											<v-row>
												<v-col cols="12" sm="12">
													<v-combobox
														v-model="searchForm.inciName"
														:items="inciResults"
														label="INCI Name"
														outlined
														dense
														clearable
														@change="
															($event) => {
																if ($event) {
																	this.searchForm.isWildcardSearch = false;
																}
															}
														"
														@input.native="
															($event) => productSearchInput($event, 'inciName')
														"
														@click:clear="
															searchForm.inciName = '';
															inciResults = [];
														"
														@keyup.enter="submit()"
														:no-data-text="noDataText"
														append-icon=""
														class="px-3"
														hide-details
													></v-combobox>
												</v-col>
											</v-row>

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>KB Value</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.kbValueMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.kbValueMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Aniline Point</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.anilinePointMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.anilinePointMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
																<v-col cols="3" offset="1">
																	<v-select
																		v-model="searchForm.anilinePointType"
																		class="mt-0 pt-0"
																		:items="pointTypes"
																		item-text="name"
																		item-value="id"
																		hide-details
																		single-line
																	></v-select>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Evaporation Rate</v-card-title>
														<v-card-subtitle>(n-Butyl Acetate = 100)</v-card-subtitle>
														<v-card-text>
															<v-row>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.evaporationRateMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.evaporationRateMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Flash Point</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.flashPointMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.flashPointMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
																<v-col cols="3" offset="1">
																	<v-select
																		v-model="searchForm.flashPointType"
																		class="mt-0 pt-0"
																		:items="pointTypes"
																		item-text="name"
																		item-value="id"
																		hide-details
																		single-line
																	></v-select>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Boiling Point Min</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.boilingPointMinMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.boilingPointMinMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
																<v-col cols="3" offset="1">
																	<v-select
																		v-model="searchForm.boilingPointTypeMin"
																		class="mt-0 pt-0"
																		:items="pointTypes"
																		item-text="name"
																		item-value="id"
																		hide-details
																		single-line
																	></v-select>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Boiling Point Max</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.boilingPointMaxMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.boilingPointMaxMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
																<v-col cols="3" offset="1">
																	<v-select
																		v-model="searchForm.boilingPointTypeMax"
																		class="mt-0 pt-0"
																		:items="pointTypes"
																		item-text="name"
																		item-value="id"
																		hide-details
																		single-line
																	></v-select>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>HLB Value</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.hlbValueMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.hlbValueMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Cloud Point</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.cloudPointMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="4">
																	<v-text-field
																		v-model="searchForm.cloudPointMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
																<v-col cols="3" offset="1">
																	<v-select
																		v-model="searchForm.cloudPointType"
																		class="mt-0 pt-0"
																		:items="pointTypes"
																		item-text="name"
																		item-value="id"
																		hide-details
																		single-line
																	></v-select>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Relative Solubility #</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.relativeSolubilityMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.relativeSolubilityMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<template>
													<v-card flat color="transparent">
														<v-card-title>Required HLB</v-card-title>
														<v-card-text>
															<v-row>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.requiredHlbMin"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Min"
																	></v-text-field>
																</v-col>
																<v-col cols="6">
																	<v-text-field
																		v-model="searchForm.requiredHlbMax"
																		class="mt-0 pt-0"
																		hide-details
																		single-line
																		type="number"
																		label="Max"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</template>
											</v-col>
										</v-row> -->

											<!-- <v-row>
											<v-col cols="12" sm="12" class="py-1">
												<v-select
													v-model="searchForm.foamCharacteristics"
													:items="foamCharacteristics"
													label="Foam Characteristics"
													single-line
													hide-details
													class="px-3"
												></v-select>
											</v-col>
										</v-row> -->

											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.nonHap"
													label="Non HAP"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.vocExempt"
													label="VOC Exempt"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row>
										<v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.prop65Exempt"
													label="Prop 65 Exempt"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.saferChemical"
													hide-details
													class="px-3"
												>
													<template v-slot:label>
														<span style="color: green">
															Safer Chemical
															<br />
															Ingredient List
														</span>
													</template>
												</v-checkbox>
											</v-col>
										</v-row>
										<v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.saferChoice"
													hide-details
													class="px-3"
												>
													<template v-slot:label>
														<span style="color: green">
															Safer Choice
															<br />
															Screened
														</span>
													</template>
												</v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.carbCompliant"
													label="CARB Compliant"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.foodContact"
													label="Food Contact Substance"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row>
										<v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.foodPackaging"
													label="Food Packaging Substance"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.kosher"
													label="Kosher"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.nonGmo"
													label="Non GMO"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
											<!-- <v-row style="width: 100%">
											<v-col cols="12" sm="12">
												<v-checkbox
													v-model="searchForm.cosmos"
													label="COSMOS"
													hide-details
													class="px-3"
												></v-checkbox>
											</v-col>
										</v-row> -->
										</v-list-item-content>
									</v-list-item>
								</v-list-group>
							</v-list>
						</v-col>
					</v-row>

					<template>
						<div class="mobile-btn-container">
							<v-fab-transition v-if="resultsTable && resultsTable.length == 0" hide-on-leave>
								<v-btn
									v-show="hasSearchInput && showFAB"
									class="search-btn-mobile"
									color="#ba0c2f"
									block
									rounded
									dark
									fixed
									bottom
									centered
									fab
									@click="submit()"
								>
									<v-icon>mdi-magnify</v-icon>
									Search
								</v-btn>
							</v-fab-transition>

							<v-fab-transition v-else hide-on-leave>
								<v-btn
									v-show="resultsTable && resultsTable.length > 0 && showFAB"
									class="search-btn-mobile"
									block
									rounded
									fixed
									bottom
									centered
									fab
									@click="clear()"
								>
									<v-icon>mdi-close-circle-outline</v-icon>
									Clear
								</v-btn>
							</v-fab-transition>
						</div>
					</template>
				</v-container>
			</form>
			<form v-else-if="!initialLoad" class="form">
				<transition name="fade">
					<div v-if="windowY > 150" class="products-fixed-search">
						<label>Search</label>

						<div class="form-group-input">
							<input
								type="text"
								size="50"
								maxlength="50"
								class="text"
								v-model="searchForm.product"
								@keyup="delay('liveSearch', 'product', 400)"
								@keyup.enter="submit()"
								@click="setSearchElement"
							/>
							<button
								type="button"
								class="form-group-input-clear"
								:style="!searchForm.product.length ? 'opacity: 0;' : null"
								@click="
									searchForm.product = '';
									productResults = [];
								"
							>
								X
							</button>

							<div v-if="productResults.length > 0" class="form-group-input-results">
								<span
									v-for="(text, i) in productResults"
									:key="i"
									class="form-group-input-results-item"
									@mousedown="
										text !== 'No Matches Found'
											? ((searchForm.product = text),
											  (searchForm.isWildcardSearch = false),
											  (productResults = []))
											: null
									"
									v-html="highlightText('product', text)"
								></span>
							</div>

							<button type="button" class="form-btn ml-1 mr-2" @click="submit()">Search</button>
							<button type="button" class="form-btn" @click="clear()">Clear</button>
						</div>
					</div>
				</transition>

				<div class="form-search-actions">
					<button type="button" class="form-btn d-block" @click="submit()">Search</button>
					<button type="button" class="form-btn mt-2" @click="clear()">Clear</button>
				</div>

				<div class="form-group">
					<label>Search</label>

					<div class="form-group-input">
						<input
							type="text"
							size="50"
							maxlength="50"
							class="text"
							v-model="searchForm.product"
							@keyup="delay('liveSearch', 'product', 400)"
							@keyup.enter="submit()"
							@click="setSearchElement"
						/>
						<button
							type="button"
							class="form-group-input-clear"
							:style="!searchForm.product.length ? 'opacity: 0;' : null"
							@click="
								searchForm.product = '';
								productResults = [];
							"
						>
							X
						</button>
						<p>Chemical or Trade Name, INCI Name, CAS #, Synonym</p>

						<div
							v-if="(productResults.length > 0) & (windowY <= 120)"
							class="form-group-input-results"
						>
							<span
								v-for="(text, i) in productResults"
								:key="i"
								class="form-group-input-results-item"
								@mousedown="
									text !== 'No Matches Found'
										? ((searchForm.product = text),
										  (searchForm.isWildcardSearch = false),
										  (productResults = []))
										: null
								"
								v-html="highlightText('product', text)"
							></span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label>Product Class</label>

					<div class="form-group-input">
						<input
							type="text"
							size="50"
							maxlength="50"
							class="text"
							v-model="searchForm.productClass"
							@keyup="delay('liveSearch', 'productClass', 400)"
							@keyup.enter="submit()"
							@click="setSearchElement"
						/>
						<button
							type="button"
							class="form-group-input-clear"
							:style="!searchForm.productClass.length ? 'opacity: 0;' : null"
							@click="
								searchForm.productClass = '';
								productClassResults = [];
							"
						>
							X
						</button>

						<div v-if="productClassResults.length > 0" class="form-group-input-results">
							<span
								v-for="(text, i) in productClassResults"
								:key="i"
								class="form-group-input-results-item"
								@mousedown="
									text !== 'No Matches Found'
										? ((searchForm.productClass = text),
										  (searchForm.isWildcardSearch = false),
										  (productClassResults = []))
										: null
								"
								v-html="highlightText('productClass', text)"
							></span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label>CAS Number</label>

					<div class="form-group-input">
						<input
							type="text"
							size="16"
							maxlength="16"
							class="text"
							v-model="searchForm.casNumber"
							@keyup="delay('liveSearch', 'casNumber', 400)"
							@keyup.enter="submit()"
							@click="setSearchElement"
						/>
						<button
							type="button"
							class="form-group-input-clear"
							:style="!searchForm.casNumber.length ? 'opacity: 0;' : null"
							@click="
								searchForm.casNumber = '';
								casNumberResults = [];
							"
						>
							X
						</button>

						<div v-if="casNumberResults.length > 0" class="form-group-input-results">
							<span
								v-for="(text, i) in casNumberResults"
								:key="i"
								class="form-group-input-results-item"
								@mousedown="
									text !== 'No Matches Found'
										? ((searchForm.casNumber = text),
										  (searchForm.isWildcardSearch = false),
										  (casNumberResults = []))
										: null
								"
								v-html="highlightText('casNumber', text)"
							></span>
						</div>
					</div>
				</div>

				<!-- <div class="form-group">
				<label>UN Number</label>

				<div class="form-group-input">
					<input
						type="text"
						size="14"
						maxlength="14"
						class="text"
						v-model="searchForm.unNumber"
						@keyup="delay('liveSearch', 'unNumber', 400)"
						@keyup.enter="submit()"
						@click="setSearchElement"
					/>
					<button
						type="button"
						class="form-group-input-clear"
						:style="!searchForm.unNumber.length ? 'opacity: 0;' : null"
						@click="
							searchForm.unNumber = '';
							unNumberResults = [];
						"
					>
						X
					</button>

					<div v-if="unNumberResults.length > 0" class="form-group-input-results">
						<span
							v-for="(text, i) in unNumberResults"
							:key="i"
							class="form-group-input-results-item"
							@mousedown="
								text !== 'No Matches Found'
									? ((searchForm.unNumber = text),
									  (searchForm.isWildcardSearch = false),
									  (unNumberResults = []))
									: null
							"
							v-html="highlightText('unNumber', text)"
						></span>
					</div>
				</div>
			</div> -->

				<div class="form-group">
					<label>Product Use</label>

					<div class="form-group-input">
						<input
							type="text"
							size="50"
							maxlength="50"
							class="text"
							v-model="searchForm.productUse"
							@keyup="delay('liveSearch', 'productUse', 400)"
							@keyup.enter="submit()"
							@click="setSearchElement"
						/>
						<button
							type="button"
							class="form-group-input-clear"
							:style="!searchForm.productUse.length ? 'opacity: 0;' : null"
							@click="
								searchForm.productUse = '';
								productUseResults = [];
							"
						>
							X
						</button>

						<div v-if="productUseResults.length > 0" class="form-group-input-results">
							<span
								v-for="(text, i) in productUseResults"
								:key="i"
								class="form-group-input-results-item"
								@mousedown="
									text !== 'No Matches Found'
										? ((searchForm.productUse = text),
										  (searchForm.isWildcardSearch = false),
										  (productUseResults = []))
										: null
								"
								v-html="highlightText('productUse', text)"
							></span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label>INCI Name</label>

					<div class="form-group-input">
						<input
							type="text"
							size="50"
							maxlength="50"
							class="text"
							v-model="searchForm.inciName"
							@keyup="delay('liveSearch', 'inci', 400)"
							@keyup.enter="submit()"
							@click="setSearchElement"
						/>
						<button
							type="button"
							class="form-group-input-clear"
							:style="!searchForm.inciName.length ? 'opacity: 0;' : null"
							@click="
								searchForm.inciName = '';
								inciResults = [];
							"
						>
							X
						</button>

						<div v-if="inciResults.length > 0" class="form-group-input-results">
							<span
								v-for="(text, i) in inciResults"
								:key="i"
								class="form-group-input-results-item"
								@mousedown="
									text !== 'No Matches Found'
										? ((searchForm.inciName = text),
										  (searchForm.isWildcardSearch = false),
										  (inciResults = []))
										: null
								"
								v-html="highlightText('inci', text)"
							></span>
						</div>
					</div>
				</div>

				<!-- <div class="form-group">
				<label>KB Value</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.kbValueMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.kbValueMax"
					/>
				</div>
			</div> -->

				<!-- <div class="form-group">
				<label>Aniline Point</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.anilinePointMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.anilinePointMax"
					/>
				</div>
				<v-select
					v-model="searchForm.anilinePointType"
					:items="pointTypes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details="auto"
					height="27px"
					style="max-width: 68px"
					class="align-self-center ml-2"
				></v-select>
			</div> -->

				<!-- <div class="form-group">
				<label>Evaporation Rate</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.evaporationRateMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.evaporationRateMax"
					/>
				</div>
				<span class="ml-2" style="font-size: 1.2rem">(n-Butyl Acetate = 100)</span>
			</div> -->

				<!-- <div class="form-group">
				<label>Flash Point</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.flashPointMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.flashPointMax"
					/>
				</div>
				<v-select
					v-model="searchForm.flashPointType"
					:items="pointTypes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details="auto"
					height="27px"
					style="max-width: 68px"
					class="align-self-center ml-2"
				></v-select>
			</div> -->

				<!-- <div class="form-group">
				<label>Boiling Point Min</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.boilingPointMinMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.boilingPointMinMax"
					/>
				</div>
				<v-select
					v-model="searchForm.boilingPointTypeMin"
					:items="pointTypes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details="auto"
					height="27px"
					style="max-width: 68px"
					class="align-self-center ml-2"
				></v-select>
			</div>

			<div class="form-group">
				<label>Boiling Point Max</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.boilingPointMaxMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.boilingPointMaxMax"
					/>
				</div>
				<v-select
					v-model="searchForm.boilingPointTypeMax"
					:items="pointTypes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details="auto"
					height="27px"
					style="max-width: 68px"
					class="align-self-center ml-2"
				></v-select>
			</div> -->

				<!-- <div class="form-group">
				<label>HLB Value</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.hlbValueMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.hlbValueMax"
					/>
				</div>
			</div> -->

				<!-- <div class="form-group">
				<label>Cloud Point</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.cloudPointMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.cloudPointMax"
					/>
				</div>
				<v-select
					v-model="searchForm.cloudPointType"
					:items="pointTypes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details="auto"
					height="27px"
					style="max-width: 68px"
					class="align-self-center ml-2"
				></v-select>
			</div> -->

				<!-- <div class="form-group">
				<label>Relative Solubility #</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.relativeSolubilityMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.relativeSolubilityMax"
					/>
				</div>
			</div> -->

				<!-- <div class="form-group">
				<label>Required HLB</label>

				<div class="form-group-input">
					<label>Min</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.requiredHlbMin"
					/>
				</div>
				<div class="form-group-input">
					<label>Max</label>
					<input
						type="text"
						size="10"
						maxlength="10"
						class="text"
						@keyup.enter="submit()"
						v-model="searchForm.requiredHlbMax"
					/>
				</div>
			</div> -->

				<!-- <div class="form-group align-center">
				<label>Foam Characteristics</label>

				<v-select
					v-model="searchForm.foamCharacteristics"
					:items="foamCharacteristics"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 200px"
				></v-select>
				<button
					type="button"
					class="form-group-input-clear"
					style="max-height: 26px"
					:style="searchForm.foamCharacteristics == '' ? 'opacity: 0;' : null"
					@click="searchForm.foamCharacteristics = ''"
				>
					X
				</button>
			</div> -->

				<!-- <div class="form-group">
				<label>Non HAP</label>

				<input type="checkbox" v-model="searchForm.nonHap" />
			</div> -->

				<!-- <div class="form-group">
				<label>VOC Exempt</label>

				<input type="checkbox" v-model="searchForm.vocExempt" />
			</div> -->

				<!-- <div class="form-group">
				<label>Prop 65 Exempt</label>

				<input type="checkbox" v-model="searchForm.prop65Exempt" />
			</div> -->

				<!-- <div class="form-group">
				<label style="color: green">
					Safer Chemical
					<br />
					Ingredient List
				</label>

				<input type="checkbox" v-model="searchForm.saferChemical" />
			</div>

			<div class="form-group">
				<label style="color: green">
					Safer Choice
					<br />
					Screened
				</label>

				<input type="checkbox" v-model="searchForm.saferChoice" />
			</div> -->

				<!-- <div class="form-group">
				<label>CARB Compliant</label>

				<input type="checkbox" v-model="searchForm.carbCompliant" />
			</div> -->

				<!-- <div class="form-group">
				<label>Food Contact Substance</label>

				<input type="checkbox" v-model="searchForm.foodContact" />
			</div>

			<div class="form-group">
				<label>Food Packaging Substance</label>

				<input type="checkbox" v-model="searchForm.foodPackaging" />
			</div> -->

				<!-- <div class="form-group">
				<label>Kosher</label>

				<input type="checkbox" v-model="searchForm.kosher" />
			</div> -->

				<!-- <div class="form-group">
				<label>Non GMO</label>

				<input type="checkbox" v-model="searchForm.nonGmo" />
			</div> -->

				<!-- <div class="form-group">
				<label>COSMOS</label>

				<input type="checkbox" v-model="searchForm.cosmos" />
			</div> -->
			</form>

			<div v-if="resultsTable.length > 0 || resultsError" class="products-results">
				<table
					@click.right.prevent
					v-if="!resultsError"
					cellspacing="0"
					class="products-results-table rslt"
				>
					<thead>
						<tr>
							<th width="32%">
								<a class="sortable" id="srt_pname" @click="sortTable('productName')">
									Product Name
									<v-icon v-show="productNameFilter">mdi-triangle</v-icon>
								</a>
								(
								<a id="exp" class="action" @click="showDetails = !showDetails">
									{{ showDetails ? "Hide" : "Show" }} Detail
								</a>
								)(
								<a id="flt" class="action" @click="filterSelected = !filterSelected">
									{{ filterSelected ? "Disable" : "Apply" }} Filter
								</a>
								)
							</th>
							<th width="18%">
								<a class="sortable" id="srt_vendor" @click="sortTable('vendorName')">
									Vendor
									<v-icon v-show="vendorNameFilter">mdi-triangle</v-icon>
								</a>
							</th>
							<th width="22%">
								<a class="sortable" id="srt_casnum" @click="sortTable('casNumber')">
									CAS #
									<v-icon v-show="casNumberFilter">mdi-triangle</v-icon>
								</a>
							</th>
							<th width="28%">
								<a class="sortable" id="srt_ctfa" @click="sortTable('inci')">
									INCI
									<v-icon v-show="inciFilter">mdi-triangle</v-icon>
								</a>
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(result, i) in resultsTable">
							<template>
								<tr
									:key="i"
									:class="[
										i % 2 == 0 ? 'row-even' : 'row-odd',
										result.isPartner ? 'promote' : '',
									]"
									:style="
										filterSelected
											? selectedResults.find((obj) => obj.productId == result.productId) ==
											  undefined
												? 'display: none'
												: null
											: ''
									"
									@click="!filterSelected ? toggleSelected(result) : null"
								>
									<td class="c1">
										<a
											class="product-dialog"
											@click="
												if (result.isFAQ) {
													launchFAQ(result);
												} else {
													selectedResult = result;
													showDetailedView = !showDetailedView;
													logEvent(result);
												}
											"
										>
											{{ result.productName }}
										</a>
									</td>
									<td class="c2">{{ result.vendorName }}</td>
									<td class="c3">{{ result.casNumber }}</td>
									<td class="c4">{{ result.inci }}</td>
									<td class="c5"></td>
								</tr>
								<tr
									:class="[i % 2 == 0 ? 'row-even' : 'row-odd']"
									:style="
										filterSelected
											? selectedResults.find((obj) => obj.productId == result.productId) ==
											  undefined
												? 'display: none'
												: showDetails
												? ''
												: 'display: none'
											: showDetails
											? ''
											: 'display: none'
									"
									:key="`${i}-details`"
									@click="!filterSelected ? toggleSelected(result, true) : null"
								>
									<td class="c6">
										<img
											v-if="result.vendorLogoFilePath && result.vendorId && result.isPartner"
											:src="getVendorLogo(result.vendorId)"
											style="max-width: 200px"
										/>
									</td>
									<td class="c7 rslt-alt" colspan="6">
										<ul>
											<li v-if="result.synonyms">
												<b>Synonyms:</b>
												{{ result.synonyms }}
											</li>
											<li v-if="result.formerTradeNames">
												<b>Former Trade Names:</b>
												{{ result.formerTradeNames }}
											</li>
											<li v-if="result.appearance">
												<b>Appearance:</b>
												{{ result.appearance }}
											</li>
											<li v-if="result.weightPerGallon">
												<b>Weight Per Gallon:</b>
												{{ result.weightPerGallon.toFixed(4) }} lbs per gal
											</li>
											<li v-if="result.evaporationRate">
												<b>Evaporation Rate:</b>
												{{ result.evaporationRate }}
											</li>
											<li v-if="result.flashPoint">
												<b>Flash Point:</b>
												{{ result.flashPoint }}°F ({{
													calculateCelsius(result.flashPoint)
												}}°C)
											</li>
											<li v-if="result.boilingPointMin">
												<b>Boiling Point Min:</b>
												{{ result.boilingPointMin }}°F ({{
													calculateCelsius(result.boilingPointMin)
												}}°C)
											</li>
											<li v-if="result.boilingPointMax">
												<b>Boiling Point Max:</b>
												{{ result.boilingPointMax }}°F ({{
													calculateCelsius(result.boilingPointMax)
												}}°C)
											</li>
											<li v-if="result.freezePoint">
												<b>Freeze Point:</b>
												{{ result.freezePoint }}°F ({{
													calculateCelsius(result.freezePoint)
												}}°C)
												{{
													result.freezePointTo
														? ` to ${result.freezePointTo}°F (${calculateCelsius(
																result.freezePointTo
														  )}°C)`
														: ""
												}}
											</li>
											<li v-if="result.meltPoint">
												<b>Melt Point:</b>
												{{ result.meltPoint }}°F ({{
													calculateCelsius(result.meltPoint)
												}}°C)
												{{
													result.meltPointTo
														? ` to ${result.meltPointTo}°F (${calculateCelsius(
																result.meltPointTo
														  )}°C)`
														: ""
												}}
											</li>
											<li v-if="result.cloudPoint">
												<b>Cloud Point:</b>
												{{ result.cloudPoint }}°F ({{
													calculateCelsius(result.cloudPoint)
												}}°C)
											</li>
											<li v-if="result.pourPoint">
												<b>Pour Point:</b>
												{{ result.pourPoint }}°F ({{
													calculateCelsius(result.pourPoint)
												}}°C)
												{{
													result.pourPointTo
														? ` to ${result.pourPointTo}°F (${calculateCelsius(
																result.pourPointTo
														  )}°C)`
														: ""
												}}
											</li>
											<li v-if="result.anilinePoint">
												<b>Aniline Point:</b>
												{{ result.anilinePoint }}°F ({{
													calculateCelsius(result.anilinePoint)
												}}°C)
											</li>
											<li v-if="result.foamCharacteristics">
												<b>Foam Characteristics:</b>
												{{ result.foamCharacteristics }}
											</li>
											<li v-if="result.hlbValue">
												<b>HLB Value:</b>
												{{ result.hlbValue }}
											</li>
											<li v-if="result.kbValue">
												<b>KB Value:</b>
												{{ result.kbValue }}
											</li>
											<li>
												<b v-if="result.nonCombustible">
													NonCombustible{{
														resultFlagsSeperator(result, "nonCombustible") ? ", " : ""
													}}
												</b>
												<b v-if="result.nonFlammable">
													NonFlammable{{
														resultFlagsSeperator(result, "nonFlammable") ? ", " : ""
													}}
												</b>
												<b v-if="result.scil" style="color: green">
													Safer Chemical Ingredient List{{
														resultFlagsSeperator(result, "scil") ? ", " : ""
													}}
												</b>
												<b v-if="result.scs" style="color: green">
													Safer Choice Screened{{
														resultFlagsSeperator(result, "scs") ? ", " : ""
													}}
												</b>
												<b v-if="result.nonHap">
													Non HAP{{ resultFlagsSeperator(result, "nonHap") ? ", " : "" }}
												</b>
												<b v-if="result.hap">
													HAP{{ resultFlagsSeperator(result, "hap") ? ", " : "" }}
												</b>
												<b v-if="result.vocExempt">
													VOC Exempt{{
														resultFlagsSeperator(result, "vocExempt") ? ", " : ""
													}}
												</b>
												<b v-if="result.carbCompliant">
													CARB Compliant{{
														resultFlagsSeperator(result, "carbCompliant") ? ", " : ""
													}}
												</b>
												<b v-if="result.prop65Exempt">
													Prop 65 Exempt{{
														resultFlagsSeperator(result, "prop65Exempt") ? ", " : ""
													}}
												</b>
												<b v-if="result.preservatives">
													Preservatives{{
														resultFlagsSeperator(result, "preservatives") ? ", " : ""
													}}
												</b>
												<b v-if="result.reachApproved">
													Reach Approved{{
														resultFlagsSeperator(result, "reachApproved") ? ", " : ""
													}}
												</b>
												<b v-if="result.kosher">
													Kosher{{ resultFlagsSeperator(result, "kosher") ? ", " : "" }}
												</b>
												<b v-if="result.foodContactSubstance">
													Food Contact{{
														resultFlagsSeperator(result, "foodContactSubstance")
															? ", "
															: ""
													}}
												</b>
												<b v-if="result.foodPackagingSubstance">
													Food Packaging Substance{{
														resultFlagsSeperator(result, "foodPackagingSubstance")
															? ", "
															: ""
													}}
												</b>
												<b v-if="result.biodegradable">
													Biodegradable{{
														resultFlagsSeperator(result, "biodegradable") ? ", " : ""
													}}
												</b>
												<b v-if="result.cosmos">
													COSMOS{{ resultFlagsSeperator(result, "cosmos") ? ", " : "" }}
												</b>
												<b v-if="result.oneFourDioxaneFree">
													1,4-Dioxane Free{{
														resultFlagsSeperator(result, "oneFourDioxaneFree")
															? ", "
															: ""
													}}
												</b>
												<b v-if="result.usdaBioPreferred">USDA BioPreferred</b>
											</li>
											<li v-if="result.remarks">
												<b>Remarks:</b>
												{{ result.remarks }}
											</li>
											<li v-if="result.comments">
												<b>Comments:</b>
												{{ result.comments }}
											</li>
										</ul>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
				<div v-else id="noResults" style="display: inline-block">
					<p>No products match the specified search criteria.</p>
					<p>
						<router-link to="/internet-search">
							Click here to use the ChemFormation Custom Internet Search
						</router-link>
					</p>
				</div>
			</div>
			<div v-if="resultsTable.length > 0 || resultsError" style="height: 200px"></div>

			<ResultsModal
				v-if="showDetailedView"
				:productId="selectedResult.productId"
				:vendorLogo="selectedResult.isPartner ? getVendorLogo(selectedResult.vendorId) : null"
				:partnerLogo="
					vendorPartner && vendorPartner.base64VendorLogo
						? `data:image/jpeg;base64,${vendorPartner.base64VendorLogo}`
						: null
				"
				:showCrossReferences="false"
				@close="showDetailedView = false"
				@requestSample="logEvent(selectedResult, true)"
			/>
		</template>
		<template v-else>
			<span>Sorry, there was an issue validating the origin.</span>
		</template>

		<v-chip class="ma-4 powered-by-chemformation" outlined @click="navigateToChemformation">
			Powered by
			<div right class="powered-by-logo">
				<img class="ml-1" src="@/assets/svg/Chemformation_logo_Horz.svg" />
			</div>
		</v-chip>
	</div>
</template>

<script>
import ResultsModal from "@/components/ProductSearch/ResultsModal.vue";
export default {
	name: "VendorSearch",
	components: {
		ResultsModal,
	},
	data() {
		return {
			searchForm: {
				product: "",
				productClass: "",
				casNumber: "",
				unNumber: "",
				vendor: "",
				productUse: "",
				inciName: "",
				kbValueMin: null,
				kbValueMax: null,
				anilinePointMin: null,
				anilinePointMax: null,
				anilinePointType: "F",
				evaporationRateMin: null,
				evaporationRateMax: null,
				flashPointMin: null,
				flashPointMax: null,
				flashPointType: "F",
				boilingPointMinMin: null,
				boilingPointMinMax: null,
				boilingPointTypeMin: "F",
				boilingPointMaxMin: null,
				boilingPointMaxMax: null,
				boilingPointTypeMax: "F",
				hlbValueMin: null,
				hlbValueMax: null,
				cloudPointMin: null,
				cloudPointMax: null,
				cloudPointType: "F",
				relativeSolubilityMin: null,
				relativeSolubilityMax: null,
				requiredHlbMin: null,
				requiredHlbMax: null,
				foamCharacteristics: "",
				nonHap: false,
				vocExempt: false,
				prop65Exempt: false,
				saferChemical: false,
				saferChoice: false,
				carbCompliant: false,
				foodContact: false,
				foodPackaging: false,
				kosher: false,
				nonGmo: false,
				cosmos: false,
				isWildcardSearch: true,
			},
			pointTypes: [
				{ name: "°F", id: "F" },
				{ name: "°C", id: "C" },
			],
			foamCharacteristics: ["Defoaming", "Low Foam", "Moderate Foam", "Flash Foam"],
			formError: false,
			searchElement: null,
			productResults: [],
			productClassResults: [],
			casNumberResults: [],
			unNumberResults: [],
			vendorResults: [],
			productUseResults: [],
			inciResults: [],
			resultsTable: [],
			selectedResults: [],
			vendors: [],
			resultsError: false,
			filterSelected: false,
			productNameFilter: false,
			vendorNameFilter: false,
			casNumberFilter: false,
			inciFilter: false,
			showDetails: false,
			selectedResult: {},
			showDetailedView: false,
			timer: null,
			loadingResults: false,
			windowY: 0,
			lastScrollPosition: null,
			isMobile: false,
			noDataText: "No Matches Found",
			showFAB: true,
			advancedFilters: [{ title: "Advanced Search", active: false }],
			parentDomain: null,
			vendorPartner: null,
			initialLoad: true,
			isValidVendorPartner: true,
		};
	},
	methods: {
		watchScroll() {
			this.windowY = window.scrollY;
		},

		onScroll() {
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			if (currentScrollPosition < 0) {
				return;
			}
			// Stop executing this function if the difference between
			// current scroll position and last scroll position is less than some offset
			if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
				return;
			}
			this.showFAB = currentScrollPosition < this.lastScrollPosition;
			this.lastScrollPosition = currentScrollPosition;
		},

		setSearchElement(event) {
			if (this.searchElement == event.target) {
				return;
			} else {
				this.searchElement = event.target;

				//reset live search results if search element changes
				this.productResults = [];
				this.productClassResults = [];
				this.casNumberResults = [];
				this.unNumberResults = [];
				this.vendorResults = [];
				this.productUseResults = [];
				this.inciResults = [];
			}
		},

		hideLiveSearch(event) {
			//if any of the live searchs have results hide and clear all results if user clicks away from the results
			if (
				this.productResults.length > 0 ||
				this.productClassResults.length > 0 ||
				this.casNumberResults.length > 0 ||
				this.unNumberResults.length > 0 ||
				this.vendorResults.length > 0 ||
				this.productUseResults.length > 0 ||
				this.inciResults.length > 0
			) {
				if (
					event.target.classList.contains("form-group-input-results") ||
					event.target.classList.contains("form-group-input-results-item") ||
					event.target == this.searchElement
				) {
					return;
				} else {
					this.productResults = [];
					this.productClassResults = [];
					this.casNumberResults = [];
					this.unNumberResults = [];
					this.vendorResults = [];
					this.productUseResults = [];
					this.inciResults = [];
				}
			}
		},

		highlightText(type, str) {
			if (str == "No Matches Found") return str;

			if (type == "product") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.product, str, " ");
				return htmlString;
			} else if (type == "productClass") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.productClass, str, " ");
				return htmlString;
			} else if (type == "casNumber") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.casNumber, str, " ");
				return htmlString;
			} else if (type == "unNumber") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.unNumber, str, " ");
				return htmlString;
			} else if (type == "vendor") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.vendor, str, " ");
				return htmlString;
			} else if (type == "productUse") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.productUse, str, " ");
				return htmlString;
			} else if (type == "inci") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.inciName, str, " ");
				return htmlString;
			}
		},

		divide_and_conquer_replace(query, option, separator) {
			let terms, terms_esc;

			//The inner replacement function
			function divide_and_conquer_inner(bites, depth) {
				let this_term, i, bite, match, new_bites, found_all_others;

				depth = depth ? depth : 1;

				//Get the longest remaining term
				this_term = terms_esc[terms_esc.length - depth];

				//Loop all the bites
				for (i = 0; i < bites.length; i++) {
					bite = bites[i];

					//Reset the lastIndex since we're reusing the RegExp objects
					this_term.lastIndex = 0;

					//Check that we have a string (ie. do not attempt to match bites
					//that are already consumed)
					if (typeof bite === "string") {
						//Find the next matching position (if any)
						while ((match = this_term.exec(bite)) !== null) {
							new_bites = i > 0 ? bites.slice(0, i) : [];
							if (match.index > 0) {
								new_bites.push(bite.slice(0, match.index));
							}
							new_bites.push(["<b>" + match[0] + "</b>"]);
							if (this_term.lastIndex < bite.length) {
								new_bites.push(bite.slice(this_term.lastIndex));
							}
							if (i < bites.length - 1) {
								new_bites = new_bites.concat(bites.slice(i + 1));
							}

							if (terms_esc.length > depth) {
								//Attempt to find all other terms
								found_all_others = divide_and_conquer_inner(new_bites, depth + 1);

								//If we found all terms we'll pass the modified string all the
								//way up to the original callee
								if (found_all_others) {
									return found_all_others;
								}
								//Otherwise try to match current term somewhere else
								this_term.lastIndex = match.index + 1;
							} else {
								//If no terms remain we have a match
								return new_bites.join("");
							}
						}
					}
				}
				//If we reach this point at least one term was not found
				return null;
			}

			// Split query in terms at delimiter
			terms = query.split(separator).filter(Boolean);
			if (!terms.length) return option;

			//Sort terms according to length - longest term last
			terms.sort((a, b) => a.length - b.length);

			//Escape terms and store RegExp's instead of strings
			terms_esc = terms
				.map((term) => term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))
				.map((term) => new RegExp(term, "gi"));

			return divide_and_conquer_inner([option]);
		},

		delay(func, type, time) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this[func](type);
			}, time);
		},

		calculateCelsius(tempF) {
			let tempC = (tempF - 32) * (5 / 9);
			return tempC.toFixed(1);
		},

		getVendorLogo(vendorId) {
			let vendor = this.vendors.find((x) => x.vendorId === vendorId);
			if (vendor && vendor.base64VendorLogo) {
				return "data:image/jpeg;base64," + vendor.base64VendorLogo;
			}
		},

		sortTable(key) {
			//sort alphanumerically by specified key
			const alphaNumericSort = (arr = [], key) => {
				const sorter = (a, b) => {
					const isNumber = (v) => (+v).toString() === v;
					const aPart = a[key] ? a[key].match(/\d+|\D+/g) : [""];
					const bPart = b[key] ? b[key].match(/\d+|\D+/g) : [""];

					if (aPart && bPart) {
						let i = 0;
						let len = Math.min(aPart.length, bPart.length);
						while (i < len && aPart[i] === bPart[i]) {
							i++;
						}
						if (i === len) {
							return aPart.length - bPart.length;
						}

						if (isNumber(aPart[i]) && isNumber(bPart[i])) {
							return aPart[i] - bPart[i];
						}

						return aPart[i].localeCompare(bPart[i]);
					}
				};

				const sortedArr = JSON.parse(JSON.stringify(arr)).sort(sorter);
				return sortedArr;
			};

			//set appropriate active filter
			this.setActiveFilter(key);

			//return reversed results if sortedResults match otherwise return the sortedResults
			let sortedResults = alphaNumericSort(this.resultsTable, key);
			if (sortedResults[0].productId === this.resultsTable[0].productId) {
				this.resultsTable = sortedResults.reverse();
				event.target.querySelector("i").classList.add("rotate");
			} else {
				this.resultsTable = sortedResults;
				event.target.querySelector("i").classList.remove("rotate");
			}
		},

		setActiveFilter(key) {
			//set all filters to false
			this.productNameFilter = false;
			this.vendorNameFilter = false;
			this.casNumberFilter = false;
			this.inciFilter = false;

			if (key == "productName") {
				this.productNameFilter = true;
			} else if (key == "vendorName") {
				this.vendorNameFilter = true;
			} else if (key == "casNumber") {
				this.casNumberFilter = true;
			} else if (key == "inci") {
				this.inciFilter = true;
			}
		},

		toggleSelected(result, detailRow) {
			//check if target element is an anchor or not
			if (event.target.tagName == "A") return;

			//check to see if result is in selected results and add/remove
			if (this.selectedResults.find((obj) => obj.productId == result.productId)) {
				this.selectedResults = this.selectedResults.filter((obj) => obj.productId !== result.productId);
			} else {
				this.selectedResults.push(result);
			}

			//toggle selected class on the target element and its corresponding details row
			if (detailRow) {
				event.target.closest("tr").classList.toggle("selected");
				event.target.closest("tr").previousElementSibling.classList.toggle("selected");
			} else {
				event.target.closest("tr").classList.toggle("selected");
				event.target.closest("tr").nextElementSibling.classList.toggle("selected");
			}
		},

		async liveSearch(type) {
			//cancel live search if currentlly loading results
			if (this.loadingResults) {
				this.productResults = [];
				this.productClassResults = [];
				this.casNumberResults = [];
				this.unNumberResults = [];
				this.vendorResults = [];
				this.productUseResults = [];
				this.inciResults = [];
				return;
			}
			//trigger isWildCardSearch
			this.searchForm.isWildcardSearch = true;

			try {
				if (type == "product") {
					if (this.searchForm.product == "") {
						this.productResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/product`, {
							params: { searchTerm: this.searchForm.product },
						});
						this.productResults = res.data;
					}
				} else if (type == "productClass") {
					if (this.searchForm.productClass == "") {
						this.productClassResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/product-class`, {
							params: { searchTerm: this.searchForm.productClass },
						});
						this.productClassResults = res.data;
					}
				} else if (type == "casNumber") {
					if (this.searchForm.casNumber == "") {
						this.casNumberResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/cas-number`, {
							params: { searchTerm: this.searchForm.casNumber },
						});
						this.casNumberResults = res.data;
					}
				} else if (type == "unNumber") {
					if (this.searchForm.unNumber == "") {
						this.unNumberResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/un-number`, {
							params: { searchTerm: this.searchForm.unNumber },
						});
						this.unNumberResults = res.data;
					}
				} else if (type == "vendor") {
					if (this.searchForm.vendor == "") {
						this.vendorResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/vendor`, {
							params: { searchTerm: this.searchForm.vendor },
						});
						this.vendorResults = res.data;
					}
				} else if (type == "productUse") {
					if (this.searchForm.productUse == "") {
						this.productUseResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/product-use`, {
							params: { searchTerm: this.searchForm.productUse },
						});
						this.productUseResults = res.data;
					}
				} else if (type == "inci" || type == "inciName") {
					if (this.searchForm.inciName == "") {
						this.inciResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/inci`, {
							params: { searchTerm: this.searchForm.inciName },
						});
						this.inciResults = res.data;
					}
				}

				if (this.loadingResults) {
					this.productResults = [];
					this.productClassResults = [];
					this.casNumberResults = [];
					this.unNumberResults = [];
					this.vendorResults = [];
					this.productUseResults = [];
					this.inciResults = [];
				}
			} catch (error) {
				if (error.response.status == 404) {
					if (type == "product") {
						this.productResults = ["No Matches Found"];
					} else if (type == "productClass") {
						this.productClassResults = ["No Matches Found"];
					} else if (type == "casNumber") {
						this.casNumberResults = ["No Matches Found"];
					} else if (type == "unNumber") {
						this.unNumberResults = ["No Matches Found"];
					} else if (type == "vendor") {
						this.vendorResults = ["No Matches Found"];
					} else if (type == "productUse") {
						this.productUseResults = ["No Matches Found"];
					} else if (type == "inci") {
						this.inciResults = ["No Matches Found"];
					}
				}
				console.log(error);
			}
		},

		async submit() {
			this.resultsError = false;
			this.loadingResults = true;
			this.advancedFilters[0].active = false;

			//clear out live results to hide them on search and unblur the input
			event.target.blur();
			this.productResults = [];
			this.productClassResults = [];
			this.casNumberResults = [];
			this.unNumberResults = [];
			this.vendorResults = [];
			this.productUseResults = [];
			this.inciResults = [];
			this.selectedResults = [];

			let trs = document.getElementsByTagName("tr");
			if (trs && trs.length > 0) {
				Array.from(trs).forEach((tr) => {
					if (tr.classList.contains("selected")) {
						tr.classList.remove("selected");
					}
				});
			}

			try {
				let searchQuery = {
					searchTerm: this.searchForm.product,
					productClass: this.searchForm.productClass,
					casNumber: this.searchForm.casNumber,
					unNumber: this.searchForm.unNumber,
					vendor: this.searchForm.vendor,
					use: this.searchForm.productUse,
					inci: this.searchForm.inciName,
					kbMin: this.searchForm.kbValueMin,
					kbMax: this.searchForm.kbValueMax,
					anilineMin: this.searchForm.anilinePointMin,
					anilineMax: this.searchForm.anilinePointMax,
					anilineUnit: this.searchForm.anilinePointType,
					evapRateMin: this.searchForm.evaporationRateMin,
					evapRateMax: this.searchForm.evaporationRateMax,
					flashPointMin: this.searchForm.flashPointMin,
					flashPointMax: this.searchForm.flashPointMax,
					flashPointUnit: this.searchForm.flashPointType,
					boilingPointMinMin: this.searchForm.boilingPointMinMin,
					boilingPointMinMax: this.searchForm.boilingPointMinMax,
					boilingPointMinUnit: this.searchForm.boilingPointTypeMin,
					boilingPointMaxMin: this.searchForm.boilingPointMaxMin,
					boilingPointMaxMax: this.searchForm.boilingPointMaxMax,
					boilingPointMaxUnit: this.searchForm.boilingPointTypeMax,
					hlbValueMin: this.searchForm.hlbValueMin,
					hlbValueMax: this.searchForm.hlbValueMax,
					cloudPointMin: this.searchForm.cloudPointMin,
					cloudPointMax: this.searchForm.cloudPointMax,
					cloudPointUnit: this.searchForm.cloudPointType,
					relativeSolubilityMin: this.searchForm.relativeSolubilityMin,
					relativeSolubilityMax: this.searchForm.relativeSolubilityMax,
					requiredHlbMin: this.searchForm.requiredHlbMin,
					requiredHlbMax: this.searchForm.requiredHlbMax,
					foam: this.searchForm.foamCharacteristics,
					isWildcardSearch: this.searchForm.isWildcardSearch,
				};

				//only add checkbox inputs if true to query
				this.searchForm.nonHap ? (searchQuery = { ...searchQuery, nonHap: true }) : null;
				this.searchForm.vocExempt ? (searchQuery = { ...searchQuery, vocExempt: true }) : null;
				this.searchForm.prop65Exempt ? (searchQuery = { ...searchQuery, prop65Exempt: true }) : null;
				this.searchForm.saferChemical ? (searchQuery = { ...searchQuery, saferChemical: true }) : null;
				this.searchForm.saferChoice ? (searchQuery = { ...searchQuery, saferChoice: true }) : null;
				this.searchForm.carbCompliant ? (searchQuery = { ...searchQuery, carbCompliant: true }) : null;
				this.searchForm.foodContact ? (searchQuery = { ...searchQuery, foodContact: true }) : null;
				this.searchForm.foodPackaging ? (searchQuery = { ...searchQuery, foodPackaging: true }) : null;
				this.searchForm.kosher ? (searchQuery = { ...searchQuery, kosher: true }) : null;
				this.searchForm.nonGmo ? (searchQuery = { ...searchQuery, nonGmo: true }) : null;
				this.searchForm.cosmos ? (searchQuery = { ...searchQuery, cosmos: true }) : null;

				const res = await this.$http.post(`/api/search/product`, searchQuery);
				console.log(res);

				this.resultsTable = res.data.searchResults.products;
				this.loadingResults = false;
				this.showDetails = false;

				//scroll to table
				this.$nextTick(() => {
					const tableEl = document.querySelector(".products-results-table");
					const y = tableEl.getBoundingClientRect().top + window.pageYOffset - 40;
					window.scrollTo({ top: y, behavior: "smooth" });
				});
			} catch (error) {
				console.log(error);
				if (error.response.status == 404) {
					this.resultsError = true;
					this.loadingResults = false;
					//scroll to error table
					this.$nextTick(() => {
						const errorResult = document.querySelector(".products-results");
						const y = errorResult.getBoundingClientRect().top + window.pageYOffset;
						window.scrollTo({ top: y, behavior: "smooth" });
					});
				}
			}
		},

		clear() {
			window.scrollTo({ top: 0 });

			this.searchForm = {
				product: "",
				productClass: "",
				casNumber: "",
				unNumber: "",
				vendor: "",
				productUse: "",
				inciName: "",
				kbValueMin: null,
				kbValueMax: null,
				anilinePointMin: null,
				anilinePointMax: null,
				anilinePointType: "F",
				evaporationRateMin: null,
				evaporationRateMax: null,
				flashPointMin: null,
				flashPointMax: null,
				flashPointType: "F",
				boilingPointMinMin: null,
				boilingPointMinMax: null,
				boilingPointTypeMin: "F",
				boilingPointMaxMin: null,
				boilingPointMaxMax: null,
				boilingPointTypeMax: "F",
				hlbValueMin: null,
				hlbValueMax: null,
				cloudPointMin: null,
				cloudPointMax: null,
				cloudPointType: "F",
				relativeSolubilityMin: null,
				relativeSolubilityMax: null,
				requiredHlbMin: null,
				requiredHlbMax: null,
				foamCharacteristics: "",
				nonHap: false,
				vocExempt: false,
				prop65Exempt: false,
				saferChemical: false,
				saferChoice: false,
				carbCompliant: false,
				foodContact: false,
				foodPackaging: false,
				kosher: false,
				nonGmo: false,
				isWildcardSearch: true,
			};

			this.resultsTable = [];
			this.resultsError = false;
			this.showDetails = false;
			this.filterSelected = false;
		},

		logEvent(res, isSample = false) {
			let searchQuery = {};

			this.searchForm.product ? (searchQuery = { ...searchQuery, general: this.searchForm.product }) : null;
			this.searchForm.productClass
				? (searchQuery = { ...searchQuery, productClass: this.searchForm.productClass })
				: null;
			this.searchForm.casNumber
				? (searchQuery = { ...searchQuery, casNumber: this.searchForm.casNumber })
				: null;
			this.searchForm.unNumber
				? (searchQuery = { ...searchQuery, unNumber: this.searchForm.unNumber })
				: null;
			this.searchForm.vendor ? (searchQuery = { ...searchQuery, vendor: this.searchForm.vendor }) : null;
			this.searchForm.productUse
				? (searchQuery = { ...searchQuery, productUse: this.searchForm.productUse })
				: null;
			this.searchForm.inciName
				? (searchQuery = { ...searchQuery, inciName: this.searchForm.inciName })
				: null;
			this.searchForm.kbValueMin
				? (searchQuery = { ...searchQuery, kbValueMin: this.searchForm.kbValueMin })
				: null;
			this.searchForm.kbValueMax
				? (searchQuery = { ...searchQuery, kbValueMax: this.searchForm.kbValueMax })
				: null;
			this.searchForm.anilinePointMin
				? (searchQuery = { ...searchQuery, anilinePointMin: this.searchForm.anilinePointMin })
				: null;
			this.searchForm.anilinePointMax
				? (searchQuery = { ...searchQuery, anilinePointMax: this.searchForm.anilinePointMax })
				: null;
			this.searchForm.evaporationRateMin
				? (searchQuery = { ...searchQuery, evaporationRateMin: this.searchForm.evaporationRateMin })
				: null;
			this.searchForm.evaporationRateMax
				? (searchQuery = { ...searchQuery, evaporationRateMax: this.searchForm.evaporationRateMax })
				: null;
			this.searchForm.flashPointMin
				? (searchQuery = { ...searchQuery, flashPointMin: this.searchForm.flashPointMin })
				: null;
			this.searchForm.flashPointMax
				? (searchQuery = { ...searchQuery, flashPointMax: this.searchForm.flashPointMax })
				: null;
			this.searchForm.boilingPointMinMin
				? (searchQuery = { ...searchQuery, boilingPointMinMin: this.searchForm.boilingPointMinMin })
				: null;
			this.searchForm.boilingPointMinMax
				? (searchQuery = { ...searchQuery, boilingPointMinMax: this.searchForm.boilingPointMinMax })
				: null;
			this.searchForm.boilingPointMaxMin
				? (searchQuery = { ...searchQuery, boilingPointMaxMin: this.searchForm.boilingPointMaxMin })
				: null;
			this.searchForm.boilingPointMaxMax
				? (searchQuery = { ...searchQuery, boilingPointMaxMax: this.searchForm.boilingPointMaxMax })
				: null;
			this.searchForm.hlbValueMin
				? (searchQuery = { ...searchQuery, hlbValueMin: this.searchForm.hlbValueMin })
				: null;
			this.searchForm.hlbValueMax
				? (searchQuery = { ...searchQuery, hlbValueMax: this.searchForm.hlbValueMax })
				: null;
			this.searchForm.cloudPointMin
				? (searchQuery = { ...searchQuery, cloudPointMin: this.searchForm.cloudPointMin })
				: null;
			this.searchForm.cloudPointMax
				? (searchQuery = { ...searchQuery, cloudPointMax: this.searchForm.cloudPointMax })
				: null;
			this.searchForm.relativeSolubilityMin
				? (searchQuery = { ...searchQuery, relativeSolubilityMin: this.searchForm.relativeSolubilityMin })
				: null;
			this.searchForm.relativeSolubilityMax
				? (searchQuery = { ...searchQuery, relativeSolubilityMax: this.searchForm.relativeSolubilityMax })
				: null;
			this.searchForm.requiredHlbMin
				? (searchQuery = { ...searchQuery, requiredHlbMin: this.searchForm.requiredHlbMin })
				: null;
			this.searchForm.requiredHlbMax
				? (searchQuery = { ...searchQuery, requiredHlbMax: this.searchForm.requiredHlbMax })
				: null;
			this.searchForm.foamCharacteristics
				? (searchQuery = { ...searchQuery, foamCharacteristics: this.searchForm.foamCharacteristics })
				: null;

			this.searchForm.nonHap ? (searchQuery = { ...searchQuery, nonHap: true }) : null;
			this.searchForm.vocExempt ? (searchQuery = { ...searchQuery, vocExempt: true }) : null;
			this.searchForm.prop65Exempt ? (searchQuery = { ...searchQuery, prop65Exempt: true }) : null;
			this.searchForm.saferChemical ? (searchQuery = { ...searchQuery, saferChemical: true }) : null;
			this.searchForm.saferChoice ? (searchQuery = { ...searchQuery, saferChoice: true }) : null;
			this.searchForm.carbCompliant ? (searchQuery = { ...searchQuery, carbCompliant: true }) : null;
			this.searchForm.foodContact ? (searchQuery = { ...searchQuery, foodContact: true }) : null;
			this.searchForm.foodPackaging ? (searchQuery = { ...searchQuery, foodPackaging: true }) : null;
			this.searchForm.kosher ? (searchQuery = { ...searchQuery, kosher: true }) : null;
			this.searchForm.nonGmo ? (searchQuery = { ...searchQuery, nonGmo: true }) : null;

			let user = this.$store.getters.userInfo;
			let payload = {
				username: user && user.username ? user.username : this.parentDomain,
				productId: res.productId,
				searchCriteria: JSON.stringify(searchQuery),
				sample: isSample ? "sample" : null,
			};

			this.$http.post(`/api/search/log`, payload);
		},

		launchFAQ(res) {
			window.open(this.getFaqLink(res.link, res.linkDropboxId), "_blank");
		},

		getFaqLink(fileLink, dropboxId) {
			if (fileLink) {
				let isFilePath = fileLink.startsWith("/");
				let parts = fileLink.split(".");
				let extension = parts.pop();
				let isPdf = extension.includes("pdf");
				return isFilePath
					? `/api/storage/${isPdf ? "preview" : "download"}-file?filePath=` +
							encodeURIComponent(fileLink) +
							"&dropboxId=" +
							dropboxId
					: fileLink;
			}
		},

		productSearchInput(event, source) {
			if (event && event.srcElement) {
				let searchContext = event.srcElement.value;
				this.searchForm[source] = searchContext;
				this.delay("liveSearch", source, 100);
			}
		},

		resultFlagsSeperator(result, type) {
			switch (type) {
				case "nonCombustible":
					if (
						result.nonFlammable ||
						result.scil ||
						result.scs ||
						result.nonHap ||
						result.hap ||
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "nonFlammable":
					if (
						result.scil ||
						result.scs ||
						result.nonHap ||
						result.hap ||
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "scil":
					if (
						result.scs ||
						result.nonHap ||
						result.hap ||
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "scs":
					if (
						result.nonHap ||
						result.hap ||
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "nonHap":
					if (
						result.hap ||
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "hap":
					if (
						result.vocExempt ||
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "vocExempt":
					if (
						result.carbCompliant ||
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "carbCompliant":
					if (
						result.prop65Exempt ||
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "prop65Exempt":
					if (
						result.preservatives ||
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "preservatives":
					if (
						result.reachApproved ||
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "reachApproved":
					if (
						result.kosher ||
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "kosher":
					if (
						result.foodContactSubstance ||
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "foodContactSubstance":
					if (
						result.foodPackagingSubstance ||
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "foodPackagingSubstance":
					if (
						result.biodegradable ||
						result.cosmos ||
						result.oneFourDioxaneFree ||
						result.usdaBioPreferred
					)
						return true;
					break;
				case "biodegradable":
					if (result.cosmos || result.oneFourDioxaneFree || result.usdaBioPreferred) return true;
					break;
				case "cosmos":
					if (result.oneFourDioxaneFree || result.usdaBioPreferred) return true;
					break;
				case "oneFourDioxaneFree":
					if (result.usdaBioPreferred) return true;
					break;
				case "usdaBioPreferred":
					return false;
				default:
					return false;
			}

			return false;
		},

		navigateToChemformation() {
			window.open("https://chemformation.com");
		},
	},
	computed: {
		hasSearchInput() {
			return (
				this.searchForm.product ||
				this.searchForm.productClass ||
				this.searchForm.casNumber ||
				this.searchForm.unNumber ||
				this.searchForm.vendor ||
				this.searchForm.productUse ||
				this.searchForm.inciName ||
				this.searchForm.kbValueMin ||
				this.searchForm.kbValueMax ||
				this.searchForm.anilinePointMin ||
				this.searchForm.anilinePointMax ||
				this.searchForm.evaporationRateMin ||
				this.searchForm.evaporationRateMax ||
				this.searchForm.flashPointMin ||
				this.searchForm.flashPointMax ||
				this.searchForm.boilingPointMinMin ||
				this.searchForm.boilingPointMinMax ||
				this.searchForm.boilingPointMaxMin ||
				this.searchForm.boilingPointMaxMax ||
				this.searchForm.hlbValueMin ||
				this.searchForm.hlbValueMax ||
				this.searchForm.cloudPointMin ||
				this.searchForm.cloudPointMax ||
				this.searchForm.relativeSolubilityMin ||
				this.searchForm.relativeSolubilityMax ||
				this.searchForm.requiredHlbMin ||
				this.searchForm.requiredHlbMax ||
				this.searchForm.foamCharacteristics ||
				this.searchForm.nonHap ||
				this.searchForm.prop65Exempt ||
				this.searchForm.saferChemical ||
				this.searchForm.saferChoice ||
				this.searchForm.carbCompliant ||
				this.searchForm.foodContact ||
				this.searchForm.foodPackaging ||
				this.searchForm.kosher ||
				this.searchForm.nonGmo
			);
		},
	},
	async mounted() {
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
		window.scrollTo({ top: 0 });

		// Determine the parent origin and validate it
		const parentOrigin =
			window.location.ancestorOrigins?.[0] || (document.referrer ? new URL(document.referrer).origin : null);

		if (parentOrigin) {
			try {
				// Validation handled by axios with the interceptor
				const res = await this.$http.post("/api/auth/validate-iframe-origin", { referrer: parentOrigin });
				if (res.data) {
					this.vendorPartner = res.data.vendor;
					this.isValidVendorPartner = true;
					console.log("Validated parent domain:", this.vendorPartner);
				} else {
					this.isValidVendorPartner = false;
					console.error("Parent domain validation failed.");
				}
			} catch (err) {
				console.error("Error validating parent domain:", err);
				this.isValidVendorPartner = false;
			} finally {
				this.initialLoad = false;
			}
		} else {
			console.warn("No parent origin detected.");
			this.isValidVendorPartner = false;
			this.initialLoad = false;
		}
	},
	async created() {
		window.addEventListener("scroll", this.watchScroll);
		window.addEventListener("scroll", this.onScroll);

		//add click listener to handle showing/hiding live search box
		window.addEventListener("click", this.hideLiveSearch);

		const res = await this.$http.get(`/api/vendor/list-vendors`);
		if (res.data.vendors) {
			this.vendors = res.data.vendors;
		}
	},
	destroyed() {
		window.removeEventListener("scroll", this.watchScroll);
		window.removeEventListener("click", this.hideLiveSearch);
		window.removeEventListener("scroll", this.onScroll);
	},
};
</script>

<style lang="scss" scoped>
.init-loader {
	position: absolute;
	top: 50vh;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
}

.loading-icon {
	z-index: 999999;
	position: fixed;
}

.rotate {
	transform: rotate(180deg);
}

.products {
	&-fixed-search {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		background-color: $color-white;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
		z-index: 10;

		label {
			width: 172px;
			display: inline-block;
			vertical-align: middle;
			font-size: 16px;
			padding: 0.2em;
			border: 1px solid white;
			margin: 2px 0px;
			margin-left: 1rem;
		}

		.form-group-input {
			display: flex;
			align-items: center;
			margin: 0.5rem 0;

			input {
				display: inline-block;
				vertical-align: middle;
				font-size: 16px;
				margin: 2px 0px;
				// border: 1px solid #a6c9e2;
				border: 1px solid #1a1a1a;
				border-radius: 5px;
				background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
				color: #222;
			}
		}
	}

	&-results {
		position: relative;
		display: block;

		// Prevent Copy-Paste
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;

		&-table {
			width: 100%;
			color: $color-black;
			border-radius: 5px;

			thead {
				th {
					.sortable {
						position: relative;
						color: $color-black;
						padding-right: 1rem;

						i {
							position: absolute;
							right: 0;
							top: 3px;
							font-size: 0.8rem;
						}
					}

					.action {
						color: #003399;
						text-decoration: underline;
					}
				}
			}

			tbody {
				td {
					&.rslt-alt {
						ul {
							list-style: none;
							padding-left: 0;
							line-height: 1.2;
						}
					}

					a {
						color: #003399;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.fade-enter-active {
	animation: drop 0.5s forwards;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-leave-to {
	opacity: 0;
}
@keyframes drop {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

.mobile-btn-container {
	display: flex;
	align-items: center;
}

.search-btn-mobile {
	min-width: 88% !important;
	display: flex;
	align-items: center;
}

.advanced-filters {
	background-color: rgba(0, 0, 0, 0.04);
}

.v-menu__content .v-select-list {
	margin-top: 0 !important;
}

.v-list-item__title {
	font-size: 16px !important;
}

.powered-by-chemformation {
	position: absolute;
	bottom: 0;
	right: 0;

	.powered-by-logo {
		display: flex;
		min-width: 150px;
		width: 150px;
		cursor: pointer;
	}
}
</style>
